import { openAppOrBrowser } from "Utils";
import AppStoreBtn from "assets/images/app-store-btn.svg";
import GoogleStoreBtn from "assets/images/google-store-btn.svg";
import PhonePreview from "assets/images/phones.svg";
import { InviteContext } from "context/inviteCodeContext";
import { FooterSectionProps } from "interfaces";
import { useContext } from "react";

const FooterSection: React.FC<FooterSectionProps> = ({ name, roomId }) => {
    const { inviteCode } = useContext(InviteContext);

    return (
        <div className='my-6 md:hidden'>
            <p className='text-white font-bold text-2xl px-10 break-all'>Exclusive Look into "{name}"</p>
            <img src={PhonePreview} alt="App Logo" className="m-auto my-8" />
            <p className='text-white mb-10 font-bold text-3xl px-10'>Available on iPhone & Android</p>
            <div className="flex flex-row justify-center px-10 gap-2">
                <img src={AppStoreBtn} alt="App Store Logo" className="w-32" onClick={() => openAppOrBrowser({ roomId, inviteCode })} />
                <img src={GoogleStoreBtn} alt="Google Play Logo" className="w-36" onClick={() => openAppOrBrowser({ roomId, inviteCode })} />
            </div>
        </div>
    );
}

export default FooterSection;
