import { InviteProvider } from 'context/inviteCodeContext';
import { UserProvider } from 'context/userCodeContext';
import UserPage from 'pages/UserPage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './pages/HomePage';

function App() {
  return (
    <div className='App'>
      <InviteProvider>
        <UserProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/:inviteCodeParam" element={<HomePage />} />
              <Route path="/u/:userId" element={<UserPage />} />
            </Routes>
          </BrowserRouter>
        </UserProvider>
      </InviteProvider>
    </div >
  );
}

export default App;
