import AppStoreBtn from "assets/images/app-store-btn.svg";
import GoogleStoreBtn from "assets/images/google-store-btn.svg";
import QRPreivew from "assets/images/phone-preview.svg";
import { DiscoverSectionProps } from "interfaces";
import React from "react";
import QRCode from "react-qr-code";

const DiscoverSection: React.FC<DiscoverSectionProps> = ({ roomId }) => {
    const appLink = `${process.env.REACT_APP_URL}/?roomId=${roomId}`;

    return (
        <div className='md:mt-14 hidden md:block'>
            <p className='text-white mb-4 font-bold text-4xl px-24'>Discover the Conversations at the Roundtable</p>
            <div className="flex justify-center px-24">
                <div className="relative">
                    <img src={QRPreivew} alt="QR Preview" className="max-w-full h-auto" />
                    <div className="absolute top-[50.8%] left-[52.4%] transform -translate-x-1/2 -translate-y-1/2 w-1/6 h-1/4">
                        <QRCode
                            size={256}
                            style={{ height: "auto", maxWidth: "90%", width: "90%", marginLeft: "8px" }}
                            value={appLink}
                            bgColor="black"
                            fgColor="#94efff"
                            viewBox={`0 0 256 256`}
                        />
                    </div>
                </div>
            </div>
            <div className='my-6 hidden md:block '>
                <p className='text-white mb-8 font-bold text-3xl px-10'>Available on iPhone & Android</p>
                <div className="flex flex-row justify-center gap-6">
                    <img src={AppStoreBtn} alt="App Logo" onClick={() => {
                        window.location.href = process.env.REACT_APP_STORE_URL ?? "";
                    }} />
                    <img src={GoogleStoreBtn} alt="Google Play Logo" onClick={() => {
                        window.location.href = process.env.REACT_APP_PLAY_STORE_URL ?? "";
                    }} />
                </div>
            </div>
        </div>
    );
}

export default DiscoverSection;
