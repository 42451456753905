import { InterestPillParam } from 'interfaces'
import React from 'react'
import styles from './InterestPill.module.css'

const InterestPill: React.FC<InterestPillParam> = ({ interest }) => {
    return (
        <div className={styles['interestPill']}>{interest}</div>
    )
}

export default InterestPill