import { openAppOrBrowser } from 'Utils';
import TopRightArrow from "assets/images/top-right-arrow.svg";
import { UserContext } from 'context/userCodeContext';
import React, { useContext } from 'react';
import styles from './NavBarButton.module.css';

type NavBarButtonProps = {
    onClick: () => void;
    title: string;
};

const NavBarButton: React.FC<NavBarButtonProps> = ({ onClick, title }) => {
    const { user } = useContext(UserContext);
    return (
        <button
            className={`${styles['nav-bar-button']} relative text-black text-base font-medium tracking-widest flex items-center space-x-2`}
            onClick={onClick}
        >
            {title}
            <img src={TopRightArrow} alt="Arrow Icon" className="" onClick={() => openAppOrBrowser({ userId: user?.id })} />
        </button>
    );
};

export default NavBarButton;
