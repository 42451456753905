import { UserInfoData } from 'interfaces';
import React, { ReactNode, createContext, useState } from 'react';

interface UserContextProps {
    user?: UserInfoData | null;
    setUser: (code: UserInfoData) => void;
}

export const UserContext = createContext<UserContextProps>({
    user: {
        id: "",
        name: "",
        headline: "",
        summary: "",
        interests: [],
        profile_picture: ""
    },
    setUser: () => { },
});

export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<UserInfoData | null>(null);

    return (
        <UserContext.Provider value={{ user, setUser }}>
            {children}
        </UserContext.Provider>
    );
};
