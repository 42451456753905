import React from 'react';

type OutlinedButtonProps = {
    onClick: () => void;
    title: string;
};

const OutlinedButton: React.FC<OutlinedButtonProps> = ({ onClick, title }) => {
    return (
        <button
            className="bg-transparent border border-white text-white w-40 h-14 px-6 py-4 rounded-full transition duration-300 ease-in-out hover:bg-white hover:text-gray-900 hover:border-transparent"
            onClick={onClick}
        >
            {title}
        </button>
    );
};

export default OutlinedButton;
