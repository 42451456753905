import React from 'react';
import styles from './PrimaryButton.module.css';

type PrimaryButtonProps = {
    onClick: () => void;
    title: string;
    width?: string;
};

const PrimaryButton: React.FC<PrimaryButtonProps> = ({ onClick, title, width = '' }) => {
    return (
        <button
            className={`${styles['primary-button']} relative text-black uppercase font-bold tracking-widest`}
            onClick={onClick}
            style={{ width }}
        >
            {title}
        </button>
    );
};

export default PrimaryButton;
