import React, { ReactNode, createContext, useState } from 'react';

interface InviteContextProps {
    inviteCode: string;
    setInviteCode: (code: string) => void;
}

export const InviteContext = createContext<InviteContextProps>({
    inviteCode: '',
    setInviteCode: () => { },
});

export const InviteProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [inviteCode, setInviteCode] = useState<string>('');

    return (
        <InviteContext.Provider value={{ inviteCode, setInviteCode }}>
            {children}
        </InviteContext.Provider>
    );
};
