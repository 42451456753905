// src/components/BottomDialog.tsx
import React from 'react';
import appLogo from "../../assets/images/logo.png";

interface BottomDialogProps {
    isOpen: boolean;
    onNext: () => void;
    onClose: () => void;
}

const BottomDialog: React.FC<BottomDialogProps> = ({ isOpen, onNext, onClose }) => {

    return (
        <div
            className={`z-10 fixed inset-0 flex items-end justify-center bg-black bg-opacity-50 transition-opacity ${isOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
                }`}
            onClick={onClose}
        >
            <div
                className={`bg-gradient-to-l from-cyan-900 to-black rounded-t-lg shadow-lg w-full p-4 transition-transform transform border-t border-gray-900 ${isOpen ? 'translate-y-0' : 'translate-y-full'
                    }`}
                onClick={(e) => e.stopPropagation()}
            >
                <div className="flex justify-between items-center ">
                    <div className="flex items-center text-left">
                        <img src={appLogo} alt="App Logo" className="h-12 w-12 mr-2" />
                        <div>
                            <p className="text-sm font-semibold text-gray-200 w-[90%] whitespace-nowrap overflow-hidden text-ellipsis">QLU Roundtables</p>
                            <p className="text-sm text-gray-500 w-[90%] whitespace-nowrap overflow-hidden text-ellipsis">Professional Networking App</p>
                        </div>
                    </div>
                    <button
                        className="px-4 py-2 text-cyan-300 border border-cyan-300 rounded hover:bg-cyan-300 hover:text-black transition-colors"
                        onClick={onNext}
                    >
                        Open App
                    </button>
                </div>
            </div>
        </div>
    );
};

export default BottomDialog;
