import { faPlay, faStop } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import placeHolder from "assets/images/user-placeholder.svg";
import { Introduction, Participant, User } from 'interfaces';
import React, { CSSProperties } from 'react';

interface UserCardProps {
    user: Participant;
    intro: Introduction | null | undefined;
    isUserPlaying: boolean;
    isAudio: boolean;
    onPlay: () => void;
    onPause: () => void;
    audioRef: (el: HTMLAudioElement | null) => HTMLAudioElement | null;
    isMobile: boolean,
    style?: CSSProperties;
}

const UserCard: React.FC<UserCardProps> = ({ user, intro, isUserPlaying, isAudio, onPlay, onPause, audioRef, isMobile = false, style }) => {

    const ImageStyle = (isUserPlaying: boolean, user: User, isAudio: boolean, customStyle?: CSSProperties) => {
        return {
            backgroundImage: isUserPlaying && !isAudio ? 'none' :
                (!user.profile_picture ? placeHolder : `url(${user.profile_picture}), url(${placeHolder})`),
            backgroundColor: isUserPlaying ? 'black' : 'transparent',
            backgroundSize: 'cover, contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top',
            height: '30vh',
            ...customStyle
        };
    };

    return (
        <div className={`relative bg-black rounded-2xl border border-gray-700 ${isMobile ? 'mx-4' : ''}`}
            style={ImageStyle(isUserPlaying, user, isAudio, style)}
            onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.style.backgroundImage = placeHolder;
            }}
        >
            {!isUserPlaying && (
                <div className="absolute bottom-0 w-full whitespace-nowrap overflow-hidden text-ellipsis text-left p-4 backdrop-blur-md bg-black bg-opacity-50 rounded-b-2xl">
                    <p className="text-white overflow-hidden text-ellipsis">{user.name}</p>
                    <p className="text-gray-400 overflow-hidden text-ellipsis">{user.headline}</p>
                </div>
            )}
            {intro && isAudio === false && (
                isUserPlaying ? (
                    <video className="h-full w-full rounded-lg" controls autoPlay muted
                        onPlay={onPlay}
                        onPause={onPause}
                    >
                        <source src={intro.path} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                ) : (
                    <div className="flex absolute items-center justify-center w-12 h-12 top-1/3 left-[43%] rounded-full border-2 border-white drop-shadow-[0_3px_3px_rgba(0,220,220,1)]">
                        <button onClick={onPlay}>
                            <FontAwesomeIcon icon={faPlay} className="text-white text-2xl" />
                        </button>
                    </div>
                )
            )}
            {intro && isAudio === true && (
                <div className="flex absolute items-center justify-center w-12 h-12 top-1/3 left-[43%] rounded-full border-2 border-white drop-shadow-[0_3px_3px_rgba(0,220,220,1)]">
                    {isUserPlaying ? (
                        <button onClick={onPause}>
                            <FontAwesomeIcon icon={faStop} className="text-white text-2xl" />
                        </button>
                    ) : (
                        <button onClick={onPlay}>
                            <FontAwesomeIcon icon={faPlay} className="text-white text-2xl" />
                        </button>
                    )}
                    <audio ref={audioRef} src={intro.path} className="hidden" onEnded={onPause} />
                </div>
            )}
        </div>
    );
};

export default UserCard;
