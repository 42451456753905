import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AudioWave from "assets/images/audio-wave.png";
import React from 'react';
import styles from './MessageBubble.module.css';

interface MessageBubbleProps {
    username: string;
    userImage: string,
    text?: string;
    image?: string;
    audio?: string;
    video?: string;
    onClick?: () => void;
}

const MessageBubble: React.FC<MessageBubbleProps> = ({ username, text, image, audio, video, userImage, onClick }) => {
    const getColor = (username: string): string => {
        const hash = username.split('').reduce((acc, char) => {
            return char.charCodeAt(0) + ((acc << 4) - acc);
        }, 0);
        const color = '#' + Math.floor(Math.abs(Math.sin(hash) * 16777215) % 16777215).toString(16);
        return color;
    };

    const userColor = getColor(username);

    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };

    const maxWidthClass = "max-w-full md:max-w-md lg:max-w-lg";

    return (
        <li className="flex items-start px-4 py-2" onClick={handleClick}>
            <img src={userImage} alt="Avatar" className="w-8 h-8 rounded-full mr-3 mt-1 border border-gray-900" />
            <div className={`bg-blue-500 text-white rounded-2xl p-2 break-words text-left relative ${styles['bubble']} ${maxWidthClass}`}>
                <p className="text-gray-600 mb-1 w-full whitespace-nowrap overflow-hidden text-ellipsis" style={{ color: userColor }}>{username}</p>
                {image && <img src={image} alt="Attached doc" className="object-cover rounded-xl" />}
                {audio && (
                    <div className="flex items-center my-2">
                        <FontAwesomeIcon icon={faPlay} className='mx-1' style={{ fontSize: '28px', color: '#80f6ff' }} />
                        <img src={AudioWave} alt="wave" style={{ maxWidth: '160px', marginLeft: '2px' }} />
                    </div>
                )}
                {video && (
                    <div className="relative">
                        <video className="object-cover rounded-xl bg-black" style={{ maxWidth: '100%', width: "100%", height: '200px' }}>
                            <source src={video} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                            <FontAwesomeIcon icon={faPlay} className='mx-1' style={{ fontSize: '28px' }} />
                        </div>
                    </div>
                )}
                {text && <p className="text-white break-words">{text}</p>}
            </div>
        </li>
    );
};

export default MessageBubble;
